<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="page-title">{{ $route.meta.title }}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Datatable
          :table-headers="tableHeaders"
          :table-data="formData"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="formItemTotal"
          :is-loading="tableLoading"
          :disable-pagination="tableLoading"
          @options-update="onTableChange"
        >
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>
 
 <script>
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'Contact',
  components: {
    Datatable,
  },
  data() {
    return {
      tableLoading: false,
      tableHeaders: [
        { text: '提交日期', value: 'create_date' },
        { text: '姓名', value: 'name' },
        { text: '電話', value: 'phone' },
        { text: '電郵', value: 'email' },
        { text: '查詢類別', value: 'enquiry_type' },
        { text: '內容', value: 'content' },
      ],
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 20,
      tablePageLimitOptions: [20, 50, 100],
      formItemTotal: 0,
      formData: [],
    }
  },

  methods: {
    async getEnquiry() {
      this.tableLoading = true

      try {
        const payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_enquiry', payload)
        this.$func.log('-----Get Enquiry-----')
        this.$func.log(result)

        this.formItemTotal = result.total

        this.formData = result.data.map(obj => {
          return { ...obj, phone: this.$validate.DataValid(obj.phone) ? obj.phone : '-' }
        })
      } catch (error) {
        this.$func.log('-----Get Enquiry fail-----')
        this.$func.log(error)

        let msg = ''
        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }

        this.formData = []
        this.formItemTotal = 0
      } finally {
        this.tableLoading = false
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      if (options.sortBy.length > 0) {
        this.tableSortKey = options.sortBy[0]
      }
      this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

      this.tablePageLimit = options.itemsPerPage
      this.getEnquiry()
    },
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.getEnquiry()
    }
  },
}
</script>
 